import React, {Component, Fragment} from "react";
import {translate} from "react-i18next";
import IconLogo from 'static/home/icons/logo.svg';
import styles from "./index.scss";
import IconSent from 'static/home/icons/sent-check.svg';
import Modal from 'components/modal/index.jsx';
import Classnames from 'classnames';
import { Form } from 'react-form';
import HintTextField from 'components/forms/hint-text-field';
import { Validation } from 'shared/Validation';
import ConfirmationDialog from "../confirmationDialog/ConfirmationDialog";
import Typography from '@material-ui/core/Typography';

class SupportModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmationDialogOpen: false,
      usedCharacters: 0,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.toggleConfirmationDialog = this.toggleConfirmationDialog.bind(this);
    this.handleTextareaChange = this.handleTextareaChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  toggleConfirmationDialog() {
    this.setState(state => ({
      isConfirmationDialogOpen: !state.isConfirmationDialogOpen,
    }));
  }
  handleCloseModal() {
    const { onClose } = this.props;
    this.setState({
      usedCharacters: 0,
    }, () => {
      onClose();
    });
  }
  onSubmit(values) {
    const { message, email } = values;
    const { onClose, errorMsg } = this.props;

    this.props.onSubmit({data: { message, email }});
    this.setState({
      usedCharacters: 0,
    });

    if(!errorMsg) {
      onClose();
      this.toggleConfirmationDialog();
    }
  }
  handleTextareaChange(e) {
    this.setState({
      usedCharacters: e.length,
    });
  }
  render() {
    const {t, open, onClose, messageSent, errorMsg} = this.props;
    const { isConfirmationDialogOpen, usedCharacters } = this.state;
    let errorStatus,
      statusClass = Classnames({
        'status': true,
        'status--opened': !!messageSent,
        'status--closed': !messageSent,
      });

    if (errorMsg) {
      errorStatus = <div className="input-row error-status"><p>{errorMsg}</p></div>;
    }

    return (
      <Fragment>
        <Modal open={open} className="support-modal" onClose={onClose}>
          <Form onSubmit={submittedValues => this.onSubmit(submittedValues)} validateOnSubmit={true}>
            {formApi => (
              <form onSubmit={formApi.submitForm}>
                <h3>{t('supportModal.title')}</h3>
                <Typography color={usedCharacters > 19 ? 'initial' : 'error'}>{`${t('contactModal.usedCharacters')}${usedCharacters} ${t('contactModal.minCharacters')}`}</Typography>
                <HintTextField
                  className="message-text-field"
                  hintText={t('contactModal.messagePlaceholder')}
                  field="message"
                  id="message"
                  multiline={true}
                  rows={6}
                  validate={Validation.contactModalMessage}
                  onChange={this.handleTextareaChange}
                />
                <HintTextField
                  hintText={t('contactModal.emailPlaceholder')}
                  field="email"
                  id="email"
                  validate={Validation.email}
                />
                <button className="button" type="submit">{t('common.send')}</button>
                <div className={statusClass}><IconSent></IconSent><p>{t('resetPassword.successMsg')}</p></div>
                {errorStatus}
              </form>
            )}
          </Form>
        </Modal>
        <ConfirmationDialog open={isConfirmationDialogOpen} onClose={this.toggleConfirmationDialog} modalMessage={t('confirmationDialog.message')}/>
      </Fragment>
    );
  }
}

export default translate("translations")(SupportModal);

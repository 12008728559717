import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Spinner from 'components/spinner';
import Close from '@material-ui/icons/Close';

import classNames from 'classnames';

const styles = theme => ({
  uiSpaceplans: {
    position: "fixed",
    cursor: "default",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "1200px",
    height: "80vh",
    zIndex: "200",
    background: "rgba(0,0,0,0.5)",
    borderRadius: "6px",
    pointerEvents: "all",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      top: 0,
      left: 0,
      transform: 'none',
      maxWidth: 'none',
      zIndex: 99,
      height: '100vh',
      overflow: 'hidden',
      paddingTop: 90,
      paddingBottom: 50,
      overflowY: 'scroll'
    },
  },
  spaceplansClose: {
    color: "white",
    position: "absolute",
    display: "inline-block",
    overflow: "hidden",
    cursor: "pointer",
    top: "0",
    right: "0",
    width: "32px",
    height: "32px",
    margin: "20px",
    "&:hover": {
      transform: "rotate(90deg)",
      transition: "all ease-in 0.2s",
    },
    [theme.breakpoints.down('sm')]: {
      top: 85
    },
  },
  spaceplansContent: {
    margin: '50px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      margin: '0 15px 0 15px',
      background: 'rgba(0,0,0,0.5)'
    },
  },
  spaceplanTitle: {
    color: '#fff',
    fontSize: '28px',
    margin: '20px',
    fontFamily: '"brandon", sans-serif',
    fontWeight: "400",
    textAlign: 'center',
  },
  spaceplanImgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 auto',
    position: 'relative',
    top: '0px',
    left: '0px',
    [theme.breakpoints.down('sm')]: {
      width: '83vw',
      margin: '0 auto',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center'
    },
  },
  spaceplansPin: {
    position: "absolute",
    display: "block",
    width: "36px",
    height: "36px",
    transform: "translate(-50%, -100%)",
    cursor: "pointer",
    zIndex: "5",
    pointerEvents: "all",
    '&:hover': {
      width: "46px",
      height: "46px",
    }
  },
  spaceplansPinActive: {
    position: "absolute",
    display: "block",
    width: "46px",
    height: "46px",
    transform: "translate(-50%, -100%)",
    cursor: "pointer",
    zIndex: "5",
    pointerEvents: "all",
  },
  spaceplansMenu: {
    width: "20%",
    height: "80%",
    flexGrow: "1",
    flexShrink: "0",
    margin: "30px",
    [theme.breakpoints.down('md')]: {
      width: '35%',
    },
    [theme.breakpoints.down('sm')]: {
      background: 'rgba(0,0,0,0.5)',
      width: '100%',
      margin: '0 15px 120px 15px',
      height: 'auto',
      overflowY: 'hidden !important',
      flexDirection: 'row',
      display: 'flex'
    },
    [theme.breakpoints.down('xs')]: {},
  },
  menuItem: {
    position: "relative",
    display: "block",
    float: "left",
    width: "100%",
    boxSizing: "border-box",
    pointerEvents: "all",
    cursor: "pointer",
    // borderRadius: "6px",
    overflow: "hidden",
    marginBottom: "20px",
    marginRight: '5px',
    height: "150px",
    maxHeight: "150px",
    [theme.breakpoints.down('sm')]: {
      width: '39%',
      margin: 20
    },
    [theme.breakpoints.down('xs')]: {
      height: "100px",
      maxHeight: "100px",
      margin: 10
    },
  },
  itemBackground: {
    display: "block",
    width: "100%",
    height: "100%",
    zIndex: 999,
    position: 'absolute',
    background: 'rgba(255, 255, 255, 0.7)',
  },
  itemBackgroundActive: {
    background: 'rgba(192,61,57, 0.7)',
  },
  spaceplanMenuItemTitle: {
    color: '#000000',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '18px',
    fontWeight: 700,
    width: '100%',
    textAlign: 'center',
  },
  image: {
    width: "100%",
    height: "100%",
  },
  spaceplansNavigation: {
    fontSize: '18px',
    color: '#fff',
    textAlign: 'center',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '83vw',
      margin: '0 auto',
      marginTop: 10,
      textAlign: 'left'
    },
    [theme.breakpoints.down('xs')]: {
      // marginTop: 0
    },
  },
  scrollOverflowY: {
    overflowY: 'scroll',
  },
});

const TITLE_HEIGHT = 32;

class SpaceplansModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSpaceplan: 0,
      spaceplansHashes: [],
      spaceplanWidth: 0,
      spaceplanHeight: 0,
      spaceplanSrc: '',
      containerWidth: 0,
      containerHeight: 0,
      sceneName: null,
      sceneId: null,
      activePin: null,
      isLoading: true,
      imgContainerW: null,
      imgContainerH: null
    };

    this.spaceplanImgContainer = React.createRef();

    this.onChangeScene = this.onChangeScene.bind(this);
    this.onChangePin = this.onChangePin.bind(this);
    this.onHoverPin = this.onHoverPin.bind(this);
    this.onHoverReset = this.onHoverReset.bind(this);
    this.onChangePlan = this.onChangePlan.bind(this);
    this.renderImg = this.renderImg.bind(this);
    this.onResizeUpdate = this.onResizeUpdate.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const {presentation, currentSpaceplan} = this.props;
    const {spaceplans, resources, scenes} = presentation || {};
    const {images} = resources || {};
    const url = window.location.href;

    const imageRatio = spaceplans[currentSpaceplan].width/spaceplans[currentSpaceplan].height;
    const spaceplanImgContainerNode = this.spaceplanImgContainer.current;
    const boundingRect = spaceplanImgContainerNode.getBoundingClientRect();
    const newHeight = boundingRect.width/imageRatio;

    let oldState = [],
      sceneName,
      sceneId,
      currentSpaceplanIndex;

    scenes.forEach(scene => {
      if (scene.id === url.slice(url.indexOf('#') + 1)) {
        sceneName = scene.name;
        sceneId = scene.id;
      }
    });

    spaceplans.forEach((spaceplan, index) => {
      spaceplan.points.forEach(point => {
        if (point.hash === url.slice(url.indexOf('#') + 1)) {
          currentSpaceplanIndex = index;
        }
      });
      if (!currentSpaceplanIndex) {
        currentSpaceplanIndex = currentSpaceplan;
      }
    });

    spaceplans.forEach(spaceplan => {
      images.forEach(image => {
        let spaceplanHash = '';
        spaceplanHash = image.id === spaceplan.resourceId ? image.hash : '';
        if (spaceplanHash.length > 1) {
          oldState.push(spaceplanHash);
        }
      });
    });

    this.setState({
      spaceplansHashes: [...oldState],
      sceneName: sceneName,
      sceneId: sceneId,
      currentSpaceplan: currentSpaceplanIndex,
      imgContainerH: newHeight
    });
    this.renderImg();
    window.addEventListener("resize", this.onResizeUpdate.bind(this));
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentDidUpdate() {
    this.renderImg();
  }

  onResizeUpdate() {
    if (!this.state.isLoading) {
      const {refreshComponent} = this.props;
      refreshComponent();
    }
  }

  renderImg() {
    const {currentSpaceplan, spaceplansHashes, spaceplanWidth, spaceplanHeight, spaceplanSrc} = this.state;
    const {presentation} = this.props;
    const {spaceplans} = presentation || {};
    const spaceplanImgContainerNode = this.spaceplanImgContainer.current;
    const boundingRect = spaceplanImgContainerNode.getBoundingClientRect();

    const spaceplanImgContainerRatio = boundingRect.width / boundingRect.height;
    let imgWidth, imgHeight, imageRatio;
    let imgSize = {};
    let src;
    if (spaceplansHashes[currentSpaceplan]) {
      src = `/api/v1/files/stream/STANDARD/${spaceplansHashes[currentSpaceplan]}`;
    }
    if (spaceplanSrc !== src && (src && src.length)) {
      const img = new Image();
      img.src = src;

      img.onload = () => {
        imgSize.width = img.width;
        imgSize.height = img.height;
        imageRatio = spaceplans[currentSpaceplan].width / spaceplans[currentSpaceplan].height;

        if (imgWidth === spaceplanWidth && imgHeight === spaceplanHeight && src === spaceplanSrc) return;

        if (spaceplanImgContainerRatio > imageRatio) {
          imgHeight = boundingRect.height - TITLE_HEIGHT;
          imgWidth = boundingRect.height * imageRatio;
          this.setState({
            spaceplanWidth: imgWidth,
            spaceplanHeight: imgHeight,
            spaceplanSrc: src,
            containerWidth: boundingRect.width,
            containerHeight: boundingRect.height,
          });
        } else {
          imgWidth = boundingRect.width;
          imgHeight = boundingRect.width / imageRatio;
          this.setState({
            spaceplanWidth: imgWidth,
            spaceplanHeight: imgHeight,
            spaceplanSrc: src,
            containerWidth: boundingRect.width,
            containerHeight: boundingRect.height,
          });
        }
        this.setState({isLoading: false});
      };
    }
  }

  onChangePin(point) {
    const {toggleSpaceplansModal, changeSceneWithPinEnabled} = this.props;
    const {hash, name} = point;
    if (changeSceneWithPinEnabled) {
      this.setState({
        sceneName: name,
      }, this.onChangeScene(hash));
      toggleSpaceplansModal();
    }
  }

  onChangeScene(sceneId) {
    const {player} = this.props;
    if (player) {
      player.changeScene({sceneId});
    }
  }

  onChangePlan(planIndex) {

    const { presentation } = this.props;
    const { spaceplans } = presentation;
    const imageRatio = spaceplans[planIndex].width/spaceplans[planIndex].height;
    const spaceplanImgContainerNode = this.spaceplanImgContainer.current;
    const boundingRect = spaceplanImgContainerNode.getBoundingClientRect();
    const newHeight = boundingRect.width/imageRatio;

    const {setPlanIndex} = this.props;
    this.setState({
      currentSpaceplan: planIndex,
      imgContainerH: newHeight
    });
    setPlanIndex(planIndex);
  }

  onHoverPin(pin) {
    this.setState({
      activePin: pin,
    });
  }

  onHoverReset() {
    this.setState({
      activePin: null,
    });
  }

  handleClickOutside(event) {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.props.toggleSpaceplansModal();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResizeUpdate.bind(this));
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  render() {
    const {presentation, classes, toggleSpaceplansModal} = this.props;
    const {spaceplans} = presentation || {};
    const {currentSpaceplan, spaceplanWidth, spaceplanHeight, spaceplansHashes, containerWidth, containerHeight, sceneName, activePin, sceneId, isLoading, imgContainerH } = this.state;
    const {points} = spaceplans[currentSpaceplan] || {};
    let pinX, pinY, pinIcon, spaceplansNames, nameOfScene;

    spaceplansNames = spaceplans.map(spaceplan => spaceplan.name);

    const renderSpaceplanesMenu = spaceplansHashes.length > 1;
    const renderSpaceplanPins = points.length > 0 ? true : false;

    if (activePin || activePin === 0) {
      nameOfScene = points[activePin].name;
    } else {
      nameOfScene = sceneName;
    }

    return (
      <Grid container className={classes.uiSpaceplans} alignItems='center' justify='space-between' wrap='nowrap'>
        <Close className={classes.spaceplansClose} onClick={toggleSpaceplansModal} color="white"/>
        <Grid container direction="column" wrap="nowrap" className={classes.spaceplansContent}>
          <Grid item className={classes.spaceplanTitle}>{spaceplansNames[currentSpaceplan]}</Grid>
          <div ref={this.spaceplanImgContainer} className={classes.spaceplanImgContainer} style={window.innerWidth < 960 ? { height: imgContainerH, minHeight: imgContainerH } : {}}>
            {!isLoading
              ? (
                <React.Fragment>
                  <img
                    crossOrigin
                    style={{
                      height: `${spaceplanHeight}px`,
                      width: `${spaceplanWidth}px`,
                      margin: '0 auto'
                    }}
                    src={`/api/v1/files/stream/STANDARD/${spaceplansHashes[currentSpaceplan]}`}
                    alt='spaceplan'/>
                  {renderSpaceplanPins && <div>
                    {points.map((point, index) => {

                      let xCorrection = (containerWidth - spaceplanWidth) / 2;
                      let yCorrection = (containerHeight - spaceplanHeight) / 2;

                      pinX = `${((+point.x) * spaceplanWidth) + xCorrection}px`;
                      pinY = `${((+point.y) * spaceplanHeight) + yCorrection}px`;

                      let isActive = activePin === index || point.hash === sceneId;

                      if (isActive) {
                        pinIcon = '/static/player/icons/jll_pin_red_active.png';
                      } else {
                        pinIcon = '/static/player/icons/jll_pin_red.png';
                      }

                      return (
                        <img
                          className={isActive ? classes.spaceplansPinActive : classes.spaceplansPin}
                          onClick={() => this.onChangePin(point)}
                          onMouseOver={() => this.onHoverPin(index)}
                          onMouseLeave={this.onHoverReset}
                          style={{top: pinY, left: pinX}}
                          crossorigin="anonymous"
                          src={pinIcon}
                        />
                      );
                    })}
                  </div>
                  }
                </React.Fragment>
              ) : (
                <Spinner/>
              )}
          </div>
          <Grid item className={classes.spaceplansNavigation}>{`${spaceplansNames[currentSpaceplan]}/${nameOfScene}`}</Grid>
        </Grid>
        {renderSpaceplanesMenu && <Grid container className={classNames(classes.spaceplansMenu, spaceplansHashes.length > 3 || window.innerHeight <= 760 ? classes.scrollOverflowY : '')} justify='center' alignItems='center'>
          {
            spaceplansHashes.map((hash, index) => (
              <div className={classes.menuItem} onClick={() => this.onChangePlan(index)} style={{
                backgroundImage: `url(/api/v1/files/stream/STANDARD/${hash})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}>
                <div
                  className={classNames(classes.itemBackground, index === currentSpaceplan ? classes.itemBackgroundActive : '')}>
                  <div className={classes.spaceplanMenuItemTitle}>{spaceplansNames[index]}</div>
                </div>
                {/*<img className={classes.image} crossOrigin='anonymous' src={`/api/v1/files/stream/STANDARD/${hash}`} />*/}
              </div>
            ))
          }
        </Grid>}
      </Grid>
    );
  }
}

SpaceplansModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  presentation: PropTypes.shape({}).isRequired,
  toggleSpaceplansModal: PropTypes.func.isRequired,
  setPlanIndex: PropTypes.func.isRequired,
  refreshComponent: PropTypes.func.isRequired,
  currentSpaceplan: PropTypes.instanceOf.isRequired,
  changeSceneWithPinEnabled: PropTypes.bool,
};

SpaceplansModal.defaultProps = {
  changeSceneWithPinEnabled: true,
};

export default withStyles(styles)(SpaceplansModal);

import React, { Component } from 'react';
import { translate } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { compose } from 'redux';
import Modal from 'components/modal/index.jsx';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import axios from 'axios/index';
import { Api } from 'api';
import './index.scss';

const styles = theme => ({
  buttonEnable: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  buttonEnableText: {
    color: 'black !important',
  },
});

class StartCoViewingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isEmailCorrect: false,
    };

    this.copyLink = this.copyLink.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.sendEmailInvite = this.sendEmailInvite.bind(this);
  }
  copyLink() {
    if (!this.linkDiv) return;

    window.getSelection().selectAllChildren( this.linkDiv );
    document.execCommand("copy");
  }
  emailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }
  sendEmailInvite() {
    const { email } = this.state,
      { onSnackbarOpen, hash, t } = this.props;

    if (!email || !hash) return;

    axios.post(
      Api.viewings.invite.replace(':hash', hash),
      {
        email: email
      }).then(
      () => {
        this.setState({
          email: '',
        });
      },
      () => {
        this.setState({
          email: '',
        });
      }
    );
    onSnackbarOpen(t('presentation.shareCoviewingNotification'));
  }
  validateEmail(inputText) {
    /* eslint-disable-next-line */
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({ isEmailCorrect: emailRegex.test(String(inputText).toLowerCase()) });
  }
  render() {
    const { t, open, onClose, onStart, loading, hash, title, reloadPage, classes } = this.props,
      { email, isEmailCorrect } = this.state,
      link = hash && `${location.protocol}//${location.host}/v/${hash}`;

    return <Modal open={open} overlay={true} onClose={onClose} className="modal--title modal--startCoViewing">
      <div className="modal-title">{title || t('presentation.viewingModal.title')}</div>
      <div className="modal-content">
        {loading && <div className="modal-loader"><div className="spinner" /></div>}
        {!loading && <div>
          <div className="hash-title">{t('presentation.viewingModal.hash')}:</div>
          <div className="hash-value">{hash}</div>
          {onStart && <input
            type="button"
            value={t('presentation.viewingModal.start')}
            className="cta-button"
            onClick={onStart}
          />}
          {reloadPage && <input
            type="button"
            value="Odśwież prezentację"
            className="cta-button"
            onClick={() => window.location.reload()}
          />}
        </div>}
      </div>
      <div className="modal-secondary-content">
        <div className="links-row">
          <div className="links-detail">
            <div className="links-label">{t('presentation.viewingModal.link')}:</div>
            <div className="link" ref={linkDiv => this.linkDiv = linkDiv }>{link}</div>
          </div>
          <div className="links-cta"><Button onClick={this.copyLink}>{t('presentation.viewingModal.copyLink')}</Button></div>
        </div>
        <div className="links-row">
          <div className="links-detail">
            <div className="links-label">{t('presentation.viewingModal.email')}:</div>
            <TextField
              className="links-textfield"
              name="email"
              placeholder={t('presentation.viewingModal.emailPlaceholder')}
              value={email}
              onChange={(e) => {
                this.emailChange(e);
                this.validateEmail(e.target.value);
              }}
            />
          </div>
          <div className="links-cta">
            <Button className={classNames('send-invite-btn', isEmailCorrect && classes.buttonEnable)} disabled={!isEmailCorrect} onClick={this.sendEmailInvite}>
              <span className={classNames(isEmailCorrect && classes.buttonEnableText)}>{t('presentation.viewingModal.sendInvite')}</span>
            </Button>
          </div>
        </div>
      </div>
    </Modal>;
  }
}

StartCoViewingModal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default compose(
  translate('translations'),
  withStyles(styles)
)(StartCoViewingModal);

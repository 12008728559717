import React, { Component } from 'react';
import { translate } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'redux';
import Modal from 'components/modal/index.jsx';
import PropTypes from 'prop-types';
import './index.scss';
import ImgVr from 'static/home/presentation/img_vr.svg';

class startCoViewingVRModal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t, open, onClose, startVrMode, startInBrowser } = this.props;

    return<div className="vr-modal">
      <div className="container">
        <div className="modal-title">{t('presentation.connectionStatusModal.welcome')}</div>
        <div className="content">
          <div className="modal-content">
            <ImgVr className="vr-img" onClick={startVrMode} />
            <input
              type="button"
              value="Start vr mode"
              className="cta-button"
              onClick={startVrMode}
            />
            <div className="stay-in-browser" onClick={startInBrowser}>{t('presentation.connectionStatusModal.stayInBrowser')}</div>
          </div>
        </div>
      </div>
    </div>;
  }
}

startCoViewingVRModal.propTypes = {
};

export default compose(
  translate('translations')
)(startCoViewingVRModal);
